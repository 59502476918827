import "./Richdad.css"
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
function atomic() {
  return (
    <>
    <article id="atomic">
            <h1>Atomic Habits</h1>
            <ol>
                <li>It is so easy to overestimate the importance of one defining moment and underestimate the value of making small
                improvements on a daily basis.</li>
                <li>Goals are about the results you want to achieve. Systems are about the processes that lead to those results.</li>
                <li>The key to building lasting habits is focusing on creating a new identity first. Your current behaviors are simply a
                reflection of your current identity. What you do now is a mirror image of the type of person you believe that you are
                (either consciously or subconsciously).</li>
            </ol>
            <Link to="/Books" style={{textDecoration: 'none', color: 'inherit'}}><Button variant="primary">Back</Button></Link>
        </article>
    </>);
}

export default atomic;