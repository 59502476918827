import "./Header.css";
import { Link } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import 'bootstrap/dist/css/bootstrap.min.css'

function Header() {
  return <>
    <div className="header">
    <a href="/" className="logo">Ahmed Najiub</a>
    <div className="header-right">
        <Link to="/">Home</Link>
        <Link to="About">Experience</Link>
        <Link to="Projects">Projects</Link>
        <DropdownButton id="dropdown-basic-button" title="Fun React Apps">
          <Dropdown.Item><Link to="Books">Books</Link></Dropdown.Item>
          <Dropdown.Item><Link to="Weather">Weather</Link></Dropdown.Item>
          <Dropdown.Item><Link to="Login">Login</Link>  </Dropdown.Item>
        </DropdownButton>
    </div>
    </div>
    </>;
}

export default Header;