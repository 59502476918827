import "./Books.css"
import Card from 'react-bootstrap/Card'
import {Link } from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css'
function Books() {
  
  return <>
  <div className="books">
      <h1>My Favorite Books</h1>
        <Link to="richdad"><Card><Card.Body>Rich Dad Poor Dad</Card.Body></Card></Link>
        <Link to="atomic"><Card><Card.Body>Atomic Habits</Card.Body></Card></Link>
        <Link to="habits"><Card><Card.Body>7 Habits of Highly Effective People</Card.Body></Card></Link>
  </div>
  </>
 }

export default Books;