import "./Footer.css";
import GitLogo from './Media/GitLogo.png';
import LinkedLogo from './Media/LinkedLogo.png';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button'
function Footer() {
  return <>
    <div className="footer">
        <a href="https://github.com/ahmedryasser"><img className="logo" src={GitLogo} /></a>
        <a href="https://www.linkedin.com/in/ahmednajiub/"><img className="logo" src={LinkedLogo} /></a>
        <Button variant="primary">
          <Link to={"/contact"}>
            Contact me
          </Link>
          </Button>
    </div>
  </>
  }

export default Footer;