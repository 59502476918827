import React, { Fragment, useState } from "react";
import "./Projects.scss";
import Button from 'react-bootstrap/Button'

import PeppLogo from "../Media/Pepperdine-logo.png"
import IpserLogo from "../Media/IpserLabLogo.png"
import PepperPlanLogo from "../Media/PepperPlan-logo.png"
import Bonobo from "../Media/Bonobo.png"
import ReactWeb from "../Media/react.png"
import Mail from "../Media/mail.jpg"



function Projects() {
  const [hoveredId, setHoveredId] = useState(null);

  function handleHover(event) {
    const id = event.target.id;
    setHoveredId(id);
  }
  function handleUnHover(event) {
    const id = event.target.id + " unhovered";
    setHoveredId(id);
  }
    return (
      <Fragment>
        <div class="section" id = "projects">
        <h1>Recent Projects</h1>
        <div class="projects">
        
        <div id= "internship" onTouchStart ={handleHover} onMouseEnter={handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "internship" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "internship" ? "disappear" : ""}`} src={Bonobo}></img>
          <div className={`hoverDiv ${hoveredId === "internship" ? "" : "disappear"}`}>
            <h4>Bonobo Git Server</h4>
            <p className="hoverTxt">
              Upgraded the University Repository Database User Interface and introduced new features, including service account and dependency tracking.
            </p>
            <Button variant="primary" href={"https://github.com/ahmedryasser/Pepperdine-App-Dev-Internship-contributions"}  className = {"button exploreBtn"} > Learn More </Button>
          </div>
        </div>
        <div id = "Pepperplan" onTouchStart={handleHover} onMouseEnter={handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "Pepperplan" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "Pepperplan" ? "disappear" : ""}`}  src={PepperPlanLogo}></img> 
          <div className={`hoverDiv ${hoveredId === "Pepperplan" ? "" : "disappear"}`}>
            <h4>PepperPlan</h4>
            <p className="hoverTxt">
              Developed Pepperplan, an ASP.Net MVC Web Application, to address class scheduling challenges faced by Pepperdine students. 
            </p>
            <Button href={"https://github.com/ahmedryasser/PepperPlan"}  className = {"button exploreBtn"} > Learn More </Button>
          </div>
        </div><div id = "portfolio" onTouchStart={handleHover} onMouseEnter={handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "portfolio" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "portfolio" ? "disappear" : ""}`} src={ReactWeb}></img>
          <div className={`hoverDiv ${hoveredId === "portfolio" ? "" : "disappear"}`}>
            <h4>This Website!</h4>
            <p className="hoverTxt">
              Engineered a dynamic web application utilizing React for the frontend, which includes projects and apps that showcase my React and API knowledge. 
            </p>
            <Button href={"https://github.com/ahmedryasser/PortfolioWebsite"}  className = {"button exploreBtn"} > Learn More </Button>
          </div>
        </div>
        <div id= "csclub" onTouchStart={handleHover} onMouseEnter={handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "csclub" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "csclub" ? "disappear" : ""}`}  src={PeppLogo}></img>
          <div className={`hoverDiv ${hoveredId === "csclub" ? "" : "disappear"}`}>
            <h4>Kotlin</h4>
            <p className="hoverTxt">
              Worked with other members in the Computer Science Club on multiple Kotlin projects, including games like Connect 4 and Memory.
            </p> 
            <Button href={"https://github.com/ahmedryasser/KotlinProjects"}  className = {"button exploreBtn"} > Learn More </Button>
          </div>
        </div>
        <div id = "ipser" onTouchStart={handleHover} onMouseEnter = {handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "ipser" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "ipser" ? "disappear" : ""}`} src={IpserLogo}></img>
          <div className={`hoverDiv ${hoveredId === "ipser" ? "" : "disappear"}`}>
            <h4>Ipser</h4>
            <p className="hoverTxt">
              I led a frontend remodeling project for a startup's website, utilizing React JS, HTML, CSS/Sass, and JavaScript within an Agile methodology. I transformed the home page design with a modern aesthetic, incorporating a designer-provided photo.
            </p>
          </div>
        </div>
        
        <div id = "kotlin" onTouchStart={handleHover} onMouseEnter={handleHover} onMouseLeave={handleUnHover} className={`project ${hoveredId === "kotlin" ? "hovered" : "unhovered"}`}>
          <img className={`${hoveredId === "kotlin" ? "disappear" : ""}`} src={Mail}></img>
          <div className={`hoverDiv ${hoveredId === "kotlin" ? "" : "disappear"}`}>
            <h4>Ahmed Mail</h4>
            <p className="hoverTxt">
              This full-stack email client and server application combines React.js and Express with advanced SMTP and IMAP handling, featuring integrated contact tracking using nedb
            </p>
          </div>
        </div>
        </div>
        <Button variant="primary" href={"https://github.com/ahmedryasser"}  className = {"button exploreBtn"} > Explore Github </Button>
        
      </div>
      </Fragment>
    );
  }
  export default Projects;