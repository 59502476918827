import Experience from "./Experience";
import './About.css'
function About() {
  return <>
  <div className="about">
      <Experience />
      
    </div>
  </>

 }

export default About;