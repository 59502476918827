import "./Richdad.css"
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
function Richdad() {
  return (
    <>
    <article id="richDad">
            <h1>Rich Dad Poor Dad</h1>
            <ol>
                <li>The poor and the middle-class work for money. The rich have money work for them.</li>
                <li>It’s not how much money you make that matters. It’s how much money you keep.</li>
                <li>Rich people acquire assets. The poor and middle class acquire liabilities that they think are assets.</li>
                <li>Financial aptitude is what you do with the money once you make it, how you keep people from taking it from you, how to
                keep it longer, and how you make money work hard for you.</li>
                <li>The single most powerful asset we all have is our minds.</li>
            </ol>
            <Link to="/Books" style={{textDecoration: 'none', color: 'inherit'}}><Button variant="primary">Back</Button></Link>
        </article>
    </>);
}

export default Richdad;