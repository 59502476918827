import React from "react";
import Button from "react-bootstrap/Button"
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useRef, useState } from "react";

import './Login.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function Login() {
  // refs
  const usernameRef = useRef();
  const passwordRef = useRef();
  const handleUsernameChange = (event) => {
    setUsernameValue(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };
  // states
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    const data = {
      username: usernameValue,
      password: passwordValue
    };
    const json = JSON.stringify(data, null, 4);
    console.clear();
    console.log(json);
    alert("Success, info now in the console in JSON format")
  };
  return (
    <>
    <div className="login">
    <Form onSubmit={handleSubmit}>
        <Row>
            <Form.Label>Log in to my portfolio! (Check the console)</Form.Label> 
        </Row>
      <Row className="align-items-center login-form">
        <Col xs="auto">
          <Form.Label htmlFor="username" visuallyHidden>
            Username
          </Form.Label>
          <InputGroup>
            <Form.Control
              ref={usernameRef}
              label="username"
              id="username"
              type="text"
              placeholder="Username"
              value={usernameValue}
              onChange={handleUsernameChange}
            />
          </InputGroup>
        </Col>
        <Col xs="auto">
          <Form.Label htmlFor="password" visuallyHidden>
            Password
          </Form.Label>
          <Form.Control
            ref={passwordRef}
            aria-label="password"
            id="password"
            type="password"
            value={passwordValue}
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </Col>
        <Col xs="auto">
          <Button type="submit" className="mb-2">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
    
    </div>
    
    </>);
}

export default Login;