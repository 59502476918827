import "./Weather.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from "react-bootstrap/Card"
import { useEffect, useState } from "react";

function Weather() {
  
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(false);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  
  const APIkey = "55d2441b4667116ca6d9930915556f2c"
  const fetchWeatherData = () => {
    if (lat !== "" && lon !== "") {
      setLoadingData(true);
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${APIkey}`
      )
      .then(response => response.json())
      .then(actualData => {
        setLoadingData(false);
        setError(false);
        setData(actualData);
      })
      .catch(err => {
        setLoadingData(false);
        setError(true);
      });
    }
  };
    const handleLongitudeChange = (event) => {
    setLon(event.target.value);
  };
    const handleLatitudeChange = (event) => {
    setLat(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    fetchWeatherData();
  };
  
  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
    });
  },[error]);
  useEffect(() => {
    fetchWeatherData();
  }, [lat, lon]);
  return <>
  <div className="landing">
    Enter Latitude and Longitude values to get the Location and current weather! Or allow the location alert and I'll get the values for you!
    <div className="fetching">
      {loadingData || error || !(
    <>loading: {loadingData ? "loading" : "not loading"}
      <br />
      error: {error ? "error" : "no error"}
      <br />
    </>)}
        <Form onSubmit={handleSubmit}>
            <Form.Label htmlFor="Longitude" visuallyHidden>
            Longitude
          </Form.Label>
          <InputGroup className="input">
            <Form.Control
              label="Longitude"
              id="Longitude"
              type="text"
              placeholder="Enter Longitude Value"
              value={lon}
              onChange={handleLongitudeChange}
            />
          </InputGroup>
           <Form.Label htmlFor="Latitude" visuallyHidden>
            Latitude
          </Form.Label>
          <InputGroup className="input">
            <Form.Control
              label="Latitude"
              id="Latitude"
              type="text"
              placeholder="Enter Latitude Value"
              value={lat}
              onChange={handleLatitudeChange}
            />
          </InputGroup>
        </Form>
      {!loadingData && !error && data && data.main && (
    <>
    <br/>
    <Card>
        <Card.Header><Card.Title>Location </Card.Title> (City - Country)</Card.Header>
       <Card.Body>{data.name} - {data.sys.country} </Card.Body> 
    </Card>
      
      <br />
      <Card >
        <Card.Header><Card.Title>Temperature</Card.Title></Card.Header>
      <Card.Body className="temperature">
        <Card>
      <Card.Title>Kelvin</Card.Title> {data.main.temp}
      </Card>
      <br/>
      <Card>
    <Card.Title>Celcius</Card.Title>{(data.main.temp - 273.15).toFixed(2)}
    </Card>
      <br/>
      <Card>
       <Card.Title>Ferenhiet</Card.Title> {(((data.main.temp- 273.15) * 9/5) + 32).toFixed(2)}
       
    </Card></Card.Body> </Card>
    </>
  )}
    </div>
      
  </div>
  </>
 }

export default Weather;