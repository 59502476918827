import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Header from "./Header";
import Footer from './Footer';
import Books from "./ReactApps/Books/Books";
import About from "./About/About";
import Login from './ReactApps/Login';
import Richdad from './ReactApps/Books/Richdad';
import Atomic from './ReactApps/Books/atomic';
import Habits from './ReactApps/Books/habits';
import Weather from './ReactApps/Weather';
import Introduction from './Introduction/Introduction';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index element={<Introduction />} />
          <Route path="About" element={<About />}></Route>
          <Route path="Login" element={<Login />}></Route>
          <Route path="Books" element={<Books />}></Route>
          <Route path="Projects" element={<Projects />}></Route>
          <Route path="Contact" element={<Contact />}></Route>
          <Route exact path="Books/richdad" element={<Richdad />}></Route>
          <Route exact path="Books/habits" element={<Habits />}></Route>
          <Route exact path="Books/atomic" element={<Atomic />}></Route>
          <Route path="Weather" element={<Weather />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

