import "./Richdad.css"
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
function habits() {
  return (
    <>
    <article id="habits">
            <h1>7 habits of highly effective people</h1>
            <ol>
                <li>Be Proactive: The first and most fundamental habit of an effective person is to be proactive. More than just taking the initiative,
                being proactive means taking responsibility for your life. Consequently, you don’t blame your behavior on external
                factors such as circumstances, but own it as part of a conscious choice based on your values. Where reactive people are
                driven by feelings, proactive people are driven by values.</li>
                <li>Begin with end in mind: Covey states that the most effective way to begin with the end in mind is to create a personal mission statement. It
                should focus on the following:
                
                What you want to be (character)
                What you want to do (contributions and achievements)
                The values upon which both of these things are based
                In time, your mission statement will become your personal constitution. It becomes the basis from which you make every
                decision in your life. By making principles the center of your life, you create a solid foundation from which to
                flourish. This is similar to the philosophy Ray Dalio presents in his book, Principles. As principles aren’t contingent
                on external factors, they don’t waver. They give you something to hold on to when times get tough. With a principle-led
                life, you can adopt a clearer, more objective worldview.</li>
                <li>Put First Things First: To begin this chapter, Covey asks you to answer the following questions:
                
                What one thing could you do regularly, that you aren’t currently doing, that would improve your personal life?
                Similarly, what one thing could you do to improve your business or professional life?
                Whereas habit one encourages you to realize you are in charge of your own life, and habit two is based on the ability to
                visualize and to identify your key values, habit three is the implementation of these two habits. It focuses on the
                practice of effective self-management through independent will. By asking yourself the above questions, you become aware
                that you have the power to significantly change your life in the present.</li>
                <li><a href="https://www.oberlo.com/blog/7-habits-of-highly-effective-people-by-stephen-covey-summary"> Read more </a></li>
            </ol>    
            <Link to="/Books" style={{textDecoration: 'none', color: 'inherit'}}><Button variant="primary">Back</Button></Link>

        </article>
    </>);
}

export default habits;